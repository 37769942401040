import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {Button, message} from 'antd';
import withIsInApp from '../../withIsInApp';
import withPageEntry from '../../withPageEntry';

async function generateIcs(event, onAfterIcsGenerate) {
  // ics file format: https://stackoverflow.com/questions/67681002/what-are-the-minimum-requirements-for-an-ics-file/67698638#67698638
  // online validator: https://icalendar.org/validator.html

  const {id, name, description, created, start, end} = event || {};

  const d = {
    uid: id
      ? `${id}${Math.ceil(Math.random() * 1000)}`
      : 'b1e4da94-1550-453f-bdee-a085a0b14231',
    name: name ? decodeURIComponent(name) : '測試',
    description: description ? description : '詳細內容',
    created: created ? created : '20200125T084646',
    start: start ? start : '20240501T120000',
    end: end ? end : '20240501T140000',
  };

  try {
    const dataStr = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nPRODID:-//revteltech//tba//EN\r\nBEGIN:VEVENT\r\nUID:${d.uid}\r\nSUMMARY:${d.name}\r\nDESCRIPTION:${d.description}\r\nDTSTAMP:${d.created}\r\nDTSTART:${d.start}\r\nDTEND:${d.end}\r\nEND:VEVENT\r\nEND:VCALENDAR`;
    const bytes = new TextEncoder('utf8').encode(dataStr);

    const url = URL.createObjectURL(
      new Blob([bytes], {
        type: 'text/calendar;charset=utf-8',
      }),
    );

    if (typeof onAfterIcsGenerate === 'function') {
      onAfterIcsGenerate(url);
    }

    return url;
  } catch (ex) {
    console.warn(ex);
    return null;
  }
}

function IcsMobile(props) {
  const params = qs.parse(props.location.search);
  const [autoClicked, setAutoClicked] = React.useState(false);
  const [url, setUrl] = React.useState(null);
  const fileRef = React.useRef();

  React.useEffect(() => {
    if (params && !url) {
      generateIcs(params, (url) => {
        setUrl(url);
      });
    }

    if (url && !autoClicked) {
      fileRef.current.click();
      setAutoClicked(true);
    }
  }, [params, url, autoClicked]);

  return (
    <Wrapper>
      {params && (
        <div className="container">
          <h2 className="title">{params.name}</h2>
          <div className="text">
            <p className="text">上課日期：{params.date}</p>
            <p className="text">
              上課時間：{params.start_time} ~ {params.end_time}
            </p>
          </div>
          <Button
            size="large"
            onClick={async () => {
              await generateIcs(params, (url) => {
                message.info('已產生 ics 檔案，點選「加入行事曆」。');
                setUrl(url);
              });
            }}>
            產生 ics 檔案
          </Button>
          {url && (
            <a className="link" ref={fileRef} href={url} download>
              加入行事曆
            </a>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: ${(props) => (props.fromMobileApp ? '0px' : 'var(--topNavBarHeight)')}
    auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 20px 40px 20px;

  & > .container > .link {
    margin-left: 20px;
  }
`;

export default withIsInApp(withPageEntry(IcsMobile));
